h1,
h2,
h3,
h4 {
  font-family: Manrope, sans-serif;
}

* {
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
}
